export const lang = {
    "company":'貝萊德量化資產',
    "copy_text":'已複製',
    "one":{
        "input_placeholder":"親輸入購買的數量",
        "language_switch":'EN',
        "user":'賬戶',
        "message":"單個用戶最多預購1000",
        "buy_text":'購入'
    },
    "body":{
        "language_switch":'EN',
        "table_hander":{
            'name':'指標',
            'price':'價格',
            "isup":'漲跌幅'
        },
        "nav_index1":'首頁',
        "nav_index2":'個人中心',
        "button_text":'授權錢包',
        "tips":'公告'
    },
    "user":{
        "language_switch":'EN',
        "num_index":'邀請人數',
        "button_index0":'邀請成員列表↓',
        "button_index1":'接入USD',
        "button_index2":'接入貝萊德',
        "nav_index1":'首頁',
        "nav_index2":'個人中心',
        "num_index2":'接入數量',
        "team_num":'團隊質押總數',
        "shouyi":'我的收益',
        "invite_text":'複製邀請碼',
          "tips":'公告'
    }
}
